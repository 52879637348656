<template>
  <div class="customerNotificationProcess">
    <div
      id="m_wizard"
      class="m-wizard m-wizard--2 m-wizard--success m-wizard--step-first"
    >
      <CustomerNotificationWizardNav
        :first-step="step == 0"
        :second-step="step == 1"
        :third-step="step == 2"
        :fourth-step="step == 3"
      />

      <div class="m-wizard__form">
        <div class="m-portlet__body">
          <CustomerNotificationStepZero
            v-if="step == 0"
            :active="step == 0"
            :step-one-obj="stepOneObj"
            @goStepTwo="stepTwo"
          />
          <CustomerNotificationStepTwo
            v-else-if="step == 1"
            :active="step == 1"
            :step-one-obj="stepOneObj"
            @goStepOne="stepOne"
            @goStepThree="stepThree"
          />
          <CustomerNotificationStepThree
            v-else-if="step == 2"
            :active="step == 2"
            :step-one-obj="stepOneObj"
            :selected-template="selectedTemplate"
            @goStepTwo="stepTwo"
            @goStepFour="stepFour"
          />
          <CustomerNotificationStepFour
            v-else-if="step == 3"
            :active="step == 3"
            :contacts-list="contactsList"
            :selected-template="selectedTemplate"
            :step-one-obj="stepOneObj"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomerNotificationProcess",
  components: {
    CustomerNotificationWizardNav: () => import('@/components/CustomerNotification/CustomerNotificationWizardNav.vue'),
    CustomerNotificationStepZero: () => import('@/components/CustomerNotification/CustomerNotificationStepZero.vue'),
    CustomerNotificationStepTwo: () => import('@/components/CustomerNotification/CustomerNotificationStepTwo.vue'),
    CustomerNotificationStepThree: () => import('@/components/CustomerNotification/CustomerNotificationStepThree.vue'),
    CustomerNotificationStepFour: () => import('@/components/CustomerNotification/CustomerNotificationStepFour.vue')
  },
  props: {

  },
  data () {
    return {
      step: 0,
      stepOneObj: {},
      selectedTemplate: null, 
      contactsList: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('customerNotification.process')
    }
  },
  methods: {
    stepOne (objOne) {
      this.stepOneObj = objOne,
      this.step = 0;
    },
    stepTwo (objOne) {
      this.stepOneObj = objOne
      this.step = 1;
    },
    stepThree (val) {
      this.selectedTemplate = val;
      this.step = 2;
    },
    stepFour (val) {
      this.contactsList = val;
      this.step = 3;
    }
  }
}
</script>
<style>
</style>